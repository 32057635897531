import BaseServiceHttp from "./BaseServiceHttp";
class Campanha extends BaseServiceHttp {
  constructor(id) {
    const resource = "campanhas";
    let relationship = {
      aprovacao: "aprovacao",
      aprovacaoLog: "aprovacao-log",
      aprovacaoPagamentoLog: "aprovacao-pagamento-log",
      apuracao: "apuracao",
      apuracaoCalculo: "apuracao/calculo",
      cadastro: "cadastro",
      campanhaTipoFechamentoSemanal: "campanha-tipo-fechamento-semanal",
      colaboradorCampanha: "colaborador",
      colaboradorProgressiva: "colaborador-progressiva",
      calculo: "calculo",
      // consultaCampanhaXLS: "xls",  //verificar rota
      bonusTipoMeta: "bonus-tipo-meta",
      crescimento: "crescimento",
      empresa: "empresas",
      equipe: "equipes",
      funcaoCampanha: "funcao",
      funcaoSemanal: "funcao/semanal",
      //metas //rever rota
      metaModeloIndividual: "meta-modelo-individual",
      metaProgressiva: "meta-progressiva",
      metaProgressivaModelo: "meta-progressiva-modelo",
      modelo: "modelo",
      chassi: "chassi",
      notificaAguardandoAprovacao: "notifica-aguardando-aprovacao",
      notificaAprovadaPagamento: "notifica-aprovada-pagamento",
      posVendaGrupoInterno: "posvenda-grupo-interno",
      posVendaGrupoInternoItem: "posvenda-grupo-interno-item",
      processLog: "process-logs",
      produtoModelo: "produto-modelo",
      prospeccao: "prospeccao",
      publico: "publico",
      publicometa: "publico-meta",
      realizado: "realizados",
      realizadoModeloIndividual: "realizado-modelo-individual",
      resultadoRealizadoCampanha: "resultado-realizado-campanha",
      resultadoRealizadoEmpresa: "resultado-realizado-empresa",
      semanaPeriodo: "semana-periodo",
      status: "status",
      tipoIndice: "tipo-indice",
      tipoCalculo: "tipo-calculo",
      tipoMeta: "tipo-meta",
      tipoMetaBonus: "tipo-meta-bonus",
      tipoMetaPagamento: "tipo-meta-pagamento",
      tipoPagamento: "tipo-pagamento"
    };
    super(id, resource, relationship);
  }
}

export default id => new Campanha(id);
