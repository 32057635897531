<template>
  <div>
    <BaseTable
      :headers="headers"
      :loading="loading"
      :paginate="true"
      :items="itemsBonusTipoMeta"
      sort-by="id_bonus_tipo_meta"
      class="table-shadow"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de bônus por tipos de Metas</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="587"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
          <FiltroBonusTipoMeta
            class="mb-5"
            @selectedFilters="fetchBonusTipoMeta((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <IconBottom :name="'edit'" v-can-access="588" @click="editItem(item)" />
        <IconBottom
          :name="'delete'"
          v-can-access="590"
          @click="deleteItem(item)"
        />
      </template>
      <template v-slot:item.valor_max="{ item }">
        {{ formatValorBonus(item.valor_max) }}
      </template>
    </BaseTable>
    <ModalBonusTipoMeta
      :bonusTipoMeta="pagamento"
      :dialog="dialog"
      :labelBtn="labelBtn"
      @close="refresh()"
    />
  </div>
</template>

<script>
import IconBottom from "@/components/shared/bottons/IconBottom";
import BaseTable from "@/components/shared/NewBaseTable";
import FiltroBonusTipoMeta from "./FiltroBonusTipoMeta";
import ModalBonusTipoMeta from "./ModalBonusTipoMeta";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import campanha from "@/services/http/campanhaService";
import formatValorBonus from "../../../utils/formatValorBonus";
export default {
  name: "TabelaBonusTipoMeta",

  components: {
    BaseTable,
    ModalBonusTipoMeta,
    FiltroBonusTipoMeta,
    RegisterBottom,
    IconBottom
  },

  data() {
    return {
      filters: {},
      labelBtn: "",
      dialog: false,
      formatValorBonus: formatValorBonus,
      pagamento: {},
      headers: [
        { text: "Segmento", value: "descricao" },
        { text: "Tipo Meta", value: "tipo_meta" },
        { text: "Valor Máx.", value: "valor_max" },
        { text: "Ações", value: "acoes" }
      ],
      itemsBonusTipoMeta: [],
      loading: false
    };
  },

  methods: {
    editItem(item) {
      this.pagamento = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    async fetchBonusTipoMeta(filters) {
      this.loading = true;
      const { data } = await campanha()
        .bonusTipoMeta()
        .show({
          per_page: -1,
          ...filters
        });

      this.itemsBonusTipoMeta = data.data;
      this.loading = false;
    },
    refresh() {
      this.dialog = false;
      this.loading = true;
      this.fetchBonusTipoMeta(this.filters);
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deseja deletar esse valor máximo para o bônus?",
          "Essa ação não pode ser desfeita"
        );
        await campanha()
          .bonusTipoMeta(item.id_bonus_tipo_meta)
          .delete(
            {},
            {
              notification: true,
              message: "Valor máx. deletado com sucesso!"
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar valor máximo"
        });
      } finally {
        this.fetchBonusTipoMeta(this.filters);
      }
    }
  },
  async mounted() {
    await this.fetchBonusTipoMeta();
  }
};
</script>
