<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="campanhas.data"
      sort-by="data_cad"
      :sort-desc="true"
      :loading="campanhas.loading"
      :paginate="true"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Campanha</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <FilterCampaign
            @selectedFilters="fetchCampanhas((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.nome_campanha`]="{ item }">
        <template v-if="Number(item.total_empresas) > 0">
          <v-badge
            v-if="Number(item.total_empresas) > 1"
            bordered
            :content="'+' + (Number(item.total_empresas) - 1)"
          >
            {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
          </v-badge>
          <template v-else>
            {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
          </template>
        </template>
        <template v-else>
          Não há empresas vinculadas
        </template>
      </template>

      <template v-slot:[`item.valor_custo`]="{ item }">
        {{ item.valor_custo | BrazilianCurrency }}
      </template>

      <template v-slot:[`item.perc_crescimento`]="{ item }">
        {{ item.perc_crescimento }}%
      </template>

      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }} -
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_cad`]="{ item }">
        {{ item.data_cad | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <!-- <v-icon color="success" v-if="item.status == 'S'">
          mdi-check
        </v-icon>
        <v-icon color="error" v-else-if="item.status == 'N'">
          mdi-close
        </v-icon>
        <v-icon color="gray" v-else>
          mdi-dots-horizontal
        </v-icon> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="success"
              v-if="item.status == 'S'"
            >
              mdi-check
            </v-icon>
            <v-icon
              v-bind="attrs"
              v-on="on"
              color="error"
              v-else-if="item.status == 'N'"
            >
              mdi-close
            </v-icon>
            <v-icon v-bind="attrs" v-on="on" color="gray" v-else>
              mdi-dots-horizontal
            </v-icon>
          </template>
          <span>{{ statusCadastro[item.status] || "Sem informações" }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.id_status_camp`]="{ item }">
        <v-chip :color="status[item.id_status_camp].color" dark>
          {{ status[item.id_status_camp].text }}
        </v-chip>
      </template>

      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        v-slot:[scopedSlotName]="slotData"
      >
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>
    </BaseTable>
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import BaseTable from "@/components/shared/NewBaseTable";
import currencyMixin from "@/mixins/currencyMixin";
import statusCampanhaMixin from "@/mixins/statusCampanhaMixin";
import noDataMixin from "@/mixins/noDataMixin";

import campanha from "@/services/http/campanhaService";
import { mapMutations } from "vuex";
import FilterCampaign from "@/components/campanha/consulta/FilterCampaign";

export default {
  name: "TabelaCampanhasRealizado",

  components: {
    BaseTable,
    FilterCampaign
  },
  props: {
    cadastro: Boolean,
    action: Boolean
  },

  mixins: [dateMixin, currencyMixin, noDataMixin, statusCampanhaMixin],

  data() {
    return {
      dialog: false,
      dialogDelete: false,
      filters: {},
      statusCadastro: {
        S: "Cadastro concluído",
        N: "Cadastro não concluído"
      },
      headers: [
        { text: "ID", value: "id_campanha" },
        {
          text: "Campanha",
          align: "start",
          value: "nome_campanha"
        },
        // { text: "Objetivo", value: "objetivo_campanha" },
        { text: "Custo Estimado", value: "valor_custo", align: "right" },
        { text: "% Cresc.", value: "perc_crescimento", align: "right" },
        { text: "Período", value: "data_inicio" },
        { text: "Criação", value: "data_cad", sortable: false },
        { text: "Cadastro", value: "status", align: "center" },
        { text: "Status", value: "id_status_camp", sortable: false }
      ],

      campanhas: {
        loading: false,
        data: []
      }
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },

  async mounted() {
    this.setCurrentCampanha("");
    await this.fetchCampanhas({ ...this.filters });

    if (this.action) {
      this.headers.push({
        text: "Ações",
        value: "actions",
        sortable: false,
        align: "center"
      });
    }
  },

  methods: {
    ...mapMutations({
      setCurrentCampanha: "campanha/setCurrentCampanha"
    }),

    calculaDiaUtil() {
      const data = new Date();
      if (data.getDate() == 1) {
        return data.getMonth();
      }
      return data.getMonth() + 1;
    },

    statusCadastramento(item) {
      let status = {
        text: "Indefinido",
        color: "gray"
      };

      switch (item.status) {
        case "S":
          (status.text = "Completo"), (status.color = "success");
          break;

        case "N":
          (status.text = "Incompleto"), (status.color = "error");
          break;
      }

      return status;
    },

    async fetchCampanhas(filter = {}) {
      this.campanhas.loading = true;
      this.loading = true;
      let { data } = await campanha().show({
        per_page: -1,
        ...filter
      });
      this.campanhas.loading = false;
      this.campanhas.data = data.data;
    }
  }
};
</script>
