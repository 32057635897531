<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>
    <v-card class="pa-3">
      <v-container>
        <BaseSelect
          single-line
          label="Tipo Meta"
          item-text="tipo_meta"
          item-value="id_tipo_meta"
          service="campanhaService.tipoMeta"
          v-model="filterBonutTipoMeta.id_tipo_meta"
          clearable
          :loading="tipoMeta.loading"
          @change="(tipoMeta.selected = $event), handleFilter()"
        >
        </BaseSelect>
        <BaseSelect
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          clearable
          v-model="filterBonutTipoMeta.id_band"
          :loading="tipoBandeira.loading"
          @change="(tipoBandeira.selected = $event), handleFilter()"
        >
        </BaseSelect>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import rules from "@/mixins/inputRules";
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseSelect from "@/components/shared/BaseSelectService";
import serviceParametros from "@/services/parametros";

export default {
  name: "FiltroBonusTipoMeta",
  mixins: [rules],

  components: {
    BaseSelect,
    FilterBottom
  },
  props: {
    tipoMetaPagamento: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      serviceParametros: serviceParametros,
      filterBonutTipoMeta: {},
      filters: {},
      tipoMeta: {
        items: [],
        loading: false,
        selected: ""
      },
      tipoBandeira: {
        items: [],
        loading: false,
        selected: ""
      }
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", {
        id_tipo_meta: this.tipoMeta.selected || null,
        id_band: this.tipoBandeira.selected || null
      });
    }
  }
};
</script>
