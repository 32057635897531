<template>
  <div class="mt-4">
    <BaseTable
      :headers="headers"
      :items="getDataTable"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="id_campanha_funcao"
      show-expand
      sort-by="cod_funcao"
      class="mb-5"
    >
      <template v-slot:top>
        <v-card-title> Metas Chassi </v-card-title>
      </template>

      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.funcao | TitleCase }}
      </template>

      <template v-slot:[`item.indicador`]="{ item }">
        {{ item.id_indicador }} - {{ item.indicador }}
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>

      <template v-slot:[`item.maior_menor`]="{ item }">
        <span v-if="item.maior_menor === '>'">
          <v-icon class="mr-1" small color="green">mdi-arrow-up </v-icon>
          Maior
        </span>
        <span v-if="item.maior_menor === '<'">
          <v-icon class="mr-1" small color="blue">mdi-arrow-down </v-icon>
          Menor
        </span>
      </template>

      <template
        v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }"
      >
        <v-btn
          color="primary"
          small
          @click="
            expand(!isExpanded);
            vincularChassis(item, false);
          "
          >expandir</v-btn
        >
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0">
          <ListaChassisParticipantes
            @atualizaChassisParticipantes="atualizaChassisParticipantes($event)"
            @atualizaChassisNaoParticipantes="
              atualizaChassisNaoParticipantes($event)
            "
            :items="chassisParticipantesFuncao(item)"
          />
        </td>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              class="ml-1"
              @click="vincularChassis(item)"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-multiple</v-icon>
            </v-btn>
          </template>
          <span>Vincular Chassi<br />a Campanha</span>
        </v-tooltip>
      </template>
    </BaseTable>

    <DraggableChassiCampanha
      :searchLoading="searchLoading"
      :dialog="dialog"
      :campanha="campanha"
      :bonusMaximo="bonusMaximo"
      :chassiNaoParticipantes="chassiNaoParticipantes"
      :chassiParticipantes="chassiParticipantes"
      @close="dialog = false"
      @atualizaChassisParticipantes="atualizaChassisParticipantes($event)"
      @atualizaChassisNaoParticipantes="atualizaChassisNaoParticipantes($event)"
      @filterChassi="fetchVeiculosEstoque($event)"
    />
  </div>
</template>

<script>
import BaseSegmento from "@/components/shared/BaseSegmento";
import BaseTable from "@/components/shared/NewBaseTable";
import { mapGetters } from "vuex";
import currencyMixin from "@/mixins/currencyMixin";
import serviceCampanha from "@/services/campanha";
import DraggableChassiCampanha from "./DraggableChassiCampanha.vue";
import ListaChassisParticipantes from "./ListaChassisParticipantes.vue";
import campanhaService from "../../../../../services/http/campanhaService";
export default {
  name: "TableMetaChassi",
  components: {
    BaseSegmento,
    BaseTable,
    ListaChassisParticipantes,
    DraggableChassiCampanha
  },

  mixins: [currencyMixin],

  data() {
    return {
      bonusMaximo: undefined,
      itemTipoMeta: {},
      itemsBonusTipoMeta: [],
      searchLoading: false,
      veiculosEstoque: [],
      headers: [
        { text: "Cód. Função", align: "start", value: "cod_funcao" },
        { text: "Função", value: "funcao" },
        { text: "segmento", value: "id_band" },
        { text: "Indicador", value: "indicador" },
        { text: "Tipo de Meta", value: "tipo_meta" },
        { text: "Base de Cálculo", value: "tipo_calculo" },

        { text: "Prioridade do Indicador", value: "maior_menor" },
        {
          text: "Vincular Chassi",
          value: "actions",
          sortable: false,
          align: "end"
        },
        { text: "Meta/Bônus", value: "data-table-expand", align: "center" }
      ],
      dialog: false,
      campanha: {},
      chassiNaoParticipantes: [],
      chassiParticipantes: [],
      expanded: [],
      singleExpand: true
    };
  },

  computed: {
    ...mapGetters({
      getMetasCadastradas: "campanha/getMetasCadastradas",
      getEmpresasParticipantes: "campanha/getEmpresasParticipantes"
    }),

    getDataTable() {
      return this.getMetasCadastradas.filter(meta => meta.id_tipo_meta == 201);
    }
  },
  watch: {
    veiculosEstoque() {
      this.atualizaChassisNaoParticipantes({
        id_funcao: this.campanha.id_funcao || undefined,
        id_indicador: this.campanha.id_indicador || undefined
      });
    },
    campanha() {
      this.fetchVeiculosEstoque({});
    }
  },
  created() {
    this.fetchVeiculosEstoque({});
    this.fetchBonusTipoMeta();
  },
  methods: {
    async fetchBonusTipoMeta() {
      try {
        const { data } = await campanhaService()
          .bonusTipoMeta()
          .show();
        this.itemsBonusTipoMeta = data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async fetchVeiculosEstoque(filter) {
      const { data } = await serviceCampanha.getVeiculosEstoque({
        ...filter,
        cod_band: this.campanha.id_band,
        per_page: 15
      });
      this.veiculosEstoque = data.data;
    },
    async atualizaChassisParticipantes(chassi) {
      const { data } = await serviceCampanha.getCampanhaChassi({
        per_page: -1,
        id_funcao: chassi.id_funcao,
        id_indicador: chassi.id_indicador,
        id_campanha: this.$route.params.idCampanha
      });
      this.chassiParticipantes = data.data;
    },

    async atualizaChassisNaoParticipantes() {
      this.searchLoading = true;
      const campanhaChassi = await serviceCampanha.getCampanhaChassi({
        per_page: -1
        // id_campanha: this.$route.params.idCampanha
      });

      const chassisEmUmaCampanha = campanhaChassi.data.data.map(
        item => item.chassi_completo
      );

      this.chassiNaoParticipantes = this.veiculosEstoque.filter(
        item =>
          !chassisEmUmaCampanha.includes(String(item.chassi_completo)) &&
          item.cod_band == this.campanha.id_band
      );
      this.searchLoading = false;
    },

    defineBonusMaximo(itemCampanhaChassi) {
      const item = this.itemsBonusTipoMeta.find(
        itemBonustipoMeta =>
          itemBonustipoMeta.id_band == itemCampanhaChassi.id_band &&
          itemBonustipoMeta.id_tipo_meta == itemCampanhaChassi.id_tipo_meta
      );

      this.bonusMaximo = parseFloat(item?.valor_max) || undefined;
    },
    async vincularChassis(itemCampanhaChassi, dialog = true) {
      this.defineBonusMaximo(itemCampanhaChassi);

      try {
        this.campanha = {
          id_band: itemCampanhaChassi.id_band,
          id_funcao: itemCampanhaChassi.id_funcao,
          id_indicador: itemCampanhaChassi.id_indicador
        };

        const campanhaChassi = await serviceCampanha.getCampanhaChassi({
          per_page: -1
          // id_campanha: this.$route.params.idCampanha
        });

        this.chassiParticipantes = campanhaChassi.data.data.filter(
          campanha =>
            campanha.id_funcao == itemCampanhaChassi.id_funcao &&
            campanha.id_indicador == itemCampanhaChassi.id_indicador
        );

        this.dialog = dialog;

        const participantes = campanhaChassi.data.data.map(
          item => item.chassi_completo
        );

        this.chassiNaoParticipantes = this.veiculosEstoque
          .filter(
            item =>
              !participantes.includes(String(item.chassi_completo)) &&
              item.cod_band == itemCampanhaChassi.id_band
          )
          .sort((a, b) =>
            parseInt(a.chassi_resumido) > parseInt(b.chassi_resumido) ? 1 : -1
          );
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: "Erro ao buscar modelos"
        });
      }
    },

    chassisParticipantesFuncao({ id_funcao, id_indicador }) {
      const equipe = this.chassiParticipantes?.filter(
        item => item.id_funcao == id_funcao && item.id_indicador == id_indicador
      );
      return equipe;
    }
  }
};
</script>
