<template>
  <div>
    <BaseTable
      v-if="items.length"
      :headers="headers"
      :items="items"
      :itemsPerPage="items.length"
      :paginate="false"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.valor_bonus`]="{ item }">
        <span v-if="item.valor_bonus">
          {{ valorBonus(item) }}
        </span>
        <span v-else>
          <v-icon color="error">mdi-close</v-icon>
        </span>
      </template>
      <template v-slot:[`item.novo_usado`]="{ item }">
        <span v-if="item.novo_usado">
          {{ novoUsado[item.novo_usado] }}
        </span>
        <span v-else>
          <v-icon color="error">mdi-close</v-icon>
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center w-100 justify-center">
          <v-btn
            small
            icon
            class="ml-1"
            @click="removeChassisParticipantes(item)"
            color="error"
            dark
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </div>
      </template>
    </BaseTable>
  </div>
</template>
<script>
import parametroService from "@/services/parametros";
import serviceColaborador from "@/services/colaborador";
import serviceCampanha from "@/services/campanha";
import BaseTable from "@/components/shared/NewBaseTable.vue";

export default {
  name: "ListaModelosParticipantes",

  components: {
    BaseTable
  },

  props: {
    items: {
      required: true,
      type: Array
    }
  },

  data() {
    return {
      search: "",
      headers: [
        { text: "Modelo", value: "descricao_modelo" },
        { text: "Novo", value: "novo_usado" },
        { text: "Chassi Completo", value: "chassi_completo", align: "center" },
        { text: "Chassi Resumido", value: "chassi_resumido", align: "center" },
        { text: "Bônus", value: "valor_bonus", align: "center" },
        { text: "Ações", value: "actions", align: "center" }
      ],

      empresasPerticipantes: "",
      dialog: false,
      dialogProgressivo: false,
      dialogList: false,
      dialogListProgressiva: false,
      campanha: {},
      colaboradoresParticipantes: [],
      colaboradoresNaoParticipantes: [],
      colaboradoresParticipantesProgressiva: [],
      colaboradoresNaoParticipantesProgressiva: [],
      novoUsado: {
        U: "Usado",
        N: "Novo"
      }
    };
  },

  mounted() {
    this.getEmpresasPerticipantes();
  },

  watch: {
    items(value) {
      return value;
    }
  },

  methods: {
    async getEmpresasPerticipantes() {
      let {
        data: { data }
      } = await serviceCampanha.getEmpresasParticipantes({
        id_campanha: this.$route.params.idCampanha
      });

      let idEmpresas = [];

      data.map(item => {
        if (item.id_empresa) {
          idEmpresas.push(item.id_empresa);
        }
      });

      this.empresasPerticipantes = idEmpresas.toString();
    },
    async removeChassisParticipantes(item) {
      try {
        const form = {
          id_campanha: this.$route.params.idCampanha,
          chassi_completo: item.chassi_completo,
          id_funcao: this.campanha.id_funcao,
          id_indicador: this.campanha.id_indicador,
          valor_bonus: this.bonusColaborador,
          chassi_resumido: item.chassi_resumido
        };

        await serviceCampanha.deleteCampanhaChassi(item.id_campanha_chassi);
        this.$notify({
          type: "warning",
          text: `Chassi ${item.descricao_modelo} removido da campanha`
        });
        this.refreshRemove(form);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `Error ao remover Chassi`
        });
      }
    },

    valorBonus(item) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL"
      }).format(item.valor_bonus);
    },

    async listarColaborador({
      id_empresa,
      id_indicador,
      id_campanha,
      id_funcao,
      id_produto
    }) {
      try {
        const {
          data: { data: colaboradores }
        } = await parametroService.getMetaModeloColaborador({
          id_indicador,
          id_campanha,
          id_empresa,
          id_funcao,
          id_produto
        });

        this.colaboradoresParticipantes = colaboradores.map(colaborador => ({
          ...colaborador
        }));
        this.dialogList = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async listarColaboradorProgressiva({
      id_empresa,
      id_indicador,
      id_campanha,
      id_funcao,
      id_produto
    }) {
      try {
        const {
          data: { data: colaboradores }
        } = await parametroService.getMetaModeloColaboradorProgressiva({
          id_indicador,
          id_campanha,
          id_empresa,
          id_funcao,
          id_produto
        });

        this.colaboradoresParticipantesProgressiva = colaboradores.map(
          colaborador => ({
            ...colaborador
          })
        );
        this.dialogListProgressiva = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async vincularColaborador(modeloCampanha) {
      this.campanha = { ...modeloCampanha };

      try {
        const participantes = await parametroService.getMetaModeloColaborador({
          id_indicador: modeloCampanha.id_indicador,
          id_campanha: modeloCampanha.id_campanha,
          id_empresa: this.empresasPerticipantes,
          id_funcao: modeloCampanha.id_funcao,
          id_produto: modeloCampanha.id_produto,
          per_page: -1
        });

        const colaboradores = await serviceColaborador.getColaboradoresEmpresa({
          id_empresa: this.empresasPerticipantes,
          id_funcao: modeloCampanha.id_funcao,
          ativo: "S",
          per_page: -1
        });

        this.colaboradoresParticipantes = participantes["data"].data;

        const ids = this.colaboradoresParticipantes.map(
          item => item.id_colaborador
        );

        this.colaboradoresNaoParticipantes = colaboradores["data"].data.filter(
          item => !ids.includes(item.id_colaborador)
        );

        this.dialog = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async vincularColaboradorProgressiva(modeloCampanha) {
      this.campanha = { ...modeloCampanha };

      try {
        const participantes = await parametroService.getMetaModeloColaboradorProgressiva(
          {
            id_indicador: modeloCampanha.id_indicador,
            id_campanha: modeloCampanha.id_campanha,
            id_empresa: this.empresasPerticipantes,
            id_funcao: modeloCampanha.id_funcao,
            id_produto: modeloCampanha.id_produto,
            per_page: -1
          }
        );

        const colaboradores = await serviceColaborador.getColaboradoresEmpresa({
          id_empresa: this.empresasPerticipantes,
          id_funcao: modeloCampanha.id_funcao,
          ativo: "S",
          per_page: -1
        });

        this.colaboradoresParticipantesProgressiva = participantes["data"].data;

        this.colaboradoresNaoParticipantesProgressiva =
          colaboradores["data"].data;

        this.dialogProgressivo = true;
      } catch {
        this.$notify({
          type: "error",
          text: "Erro ao listar os colaboradores"
        });
      }
    },

    async atualizaColaboradoresParticipantesProgressiva() {
      const {
        data
      } = await parametroService.getMetaModeloColaboradorProgressiva({
        id_indicador: this.campanha.id_indicador,
        id_campanha: this.campanha.id_campanha,
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        id_produto: this.campanha.id_produto
      });
      this.colaboradoresParticipantesProgressiva = data.data;
    },

    async atualizaColaboradoresParticipantes() {
      const { data } = await parametroService.getMetaModeloColaborador({
        id_indicador: this.campanha.id_indicador,
        id_campanha: this.campanha.id_campanha,
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        id_produto: this.campanha.id_produto
      });
      this.colaboradoresParticipantes = data.data;
    },

    async atualizaColaboradoresNaoParticipantes() {
      const participantes = await parametroService.getMetaModeloColaborador({
        id_indicador: this.campanha.id_indicador,
        id_campanha: this.campanha.id_campanha,
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        id_produto: this.campanha.id_produto
      });

      const colaboradores = await serviceColaborador.getColaboradoresEmpresa({
        id_empresa: this.empresasPerticipantes,
        id_funcao: this.campanha.id_funcao,
        ativo: "S",
        per_page: -1
      });

      const ids = participantes.data.data.map(item => item.id_colaborador);

      this.colaboradoresNaoParticipantes = colaboradores["data"].data.filter(
        item => !ids.includes(item.id_colaborador)
      );
    },

    closeModal() {
      this.dialog = false;
      this.colaboradoresParticipantes = [];
      this.colaboradoresNaoParticipantes = [];
    },

    closeModalProgressiva() {
      this.dialogProgressivo = false;
      this.colaboradoresParticipantesProgressiva = [];
      this.colaboradoresNaoParticipantesProgressiva = [];
    },
    refreshRemove(form) {
      this.$emit("atualizaChassisParticipantes", form);
      this.$emit("atualizaChassisNaoParticipantes", form);
    }
  }
};
</script>
