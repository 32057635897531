<template>
  <v-form ref="form" lazy-validation>
    <v-row dense>
      <v-col lg="4" md="4" sm="6" cols="12">
        <v-text-field
          v-model="formCampanha.nome_campanha"
          label="Nome"
          counter="50"
          :rules="[rules.required, rules.nomeLength]"
          @input="checkFormEdited()"
        ></v-text-field>
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <vuetify-money
          v-model="formCampanha.valor_custo"
          label="Custo Estimado"
          valueWhenIsEmpty="0"
          :options="optionsVuetifyMoney"
          :rules="[rules.required, rules.maiorZero]"
          @input="checkFormEdited()"
        />
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <vuetify-money
          v-model="formCampanha.perc_crescimento"
          label="Crescimento Estimado"
          valueWhenIsEmpty="0"
          :options="optionsVuetifyPercent"
          :rules="[rules.required, rules.maiorCinco]"
          @input="checkFormEdited()"
        />
      </v-col>
      <v-col lg="12" md="12" sm="12" cols="12">
        <v-textarea
          v-model="formCampanha.objetivo_campanha"
          label="Objetivo"
          counter="2000"
          :rules="[rules.required, rules.objetivoLength]"
          @input="checkFormEdited()"
        ></v-textarea>
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <BaseSelect
          :items="[{ id_tipo_evento: 'M', tipo_evento: 'CAMPANHA' }]"
          label="Tipos Eventos"
          item-text="tipo_evento"
          item-value="id_tipo_evento"
          :preSelected="{ id_tipo_evento: 'M', tipo_evento: 'CAMPANHA' }"
          disabled
          @change="tipoFechamento.id_tipo_evento = $event"
        />
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <BaseSelect
          :items="tiposDados.items"
          label="Tipos Dados"
          item-text="descricao"
          item-value="id_tipo_dado"
          :preSelected="tiposDados.selected"
          :rules="[rules.required]"
          :loading="tiposDados.loading"
          @change="
            tipoFechamento.id_tipo_dado = $event;
            $event !== 'X' ? formatDate(date, $event) : null;
            verificaDataMesFechado($event);
            checkFormEdited();
          "
        />
      </v-col>
      <v-col lg="4" md="4" sm="6" cols="12">
        <BaseDatePicker
          v-if="tipoFechamento.id_tipo_dado !== 'X'"
          label="Periodo de Fechamento"
          :disabled="tipoFechamento.id_tipo_dado === ''"
          :preDate="date"
          :minDate="minDate"
          :maxnDate="maxnDate"
          @change="
            formatDate($event, tipoFechamento.id_tipo_dado);
            checkFormEdited();
          "
        />
        <BaseRangeDatePicker
          v-else
          label="Periodo de Fechamento"
          :disabled="tipoFechamento.id_tipo_dado === ''"
          :preDates="dates"
          @change="
            formatDate($event, tipoFechamento.id_tipo_dado);
            checkFormEdited();
          "
        />
      </v-col>
    </v-row>
    <v-row class="ml-1" v-if="tipoFechamento.id_tipo_dado == 'C'">
      <TableFechamentoSemanais :fechamentoSemanal="arrayFechamentos" />
    </v-row>
    <v-btn
      :disabled="disabledSemanal()"
      color="primary"
      @click="saveCampanha()"
      class="mt-3"
    >
      Avançar
      <v-icon right>
        mdi-arrow-right
      </v-icon>
    </v-btn>
  </v-form>
</template>

<script>
import BaseRangeDatePicker from "@/components/shared/BaseRangeDatePicker";
import BaseDatePicker from "@/components/shared/NewBaseDatePicker";
import BaseSelect from "@/components/shared/NewBaseSelect";
import rules from "@/mixins/inputRules";
import campanhaService from "@/services/campanha";
import campanhas from "@/services/http/campanhaService";
import comissao from "@/services/http/comissaoService";
import {
  compareAsc,
  format,
  getMonth,
  getYear,
  lastDayOfMonth,
  parse,
  setDate,
  subMonths
} from "date-fns";
import { mapActions, mapGetters } from "vuex";
import TableFechamentoSemanais from "./TableFechamentoSemanais.vue";
export default {
  name: "FormCadastroCampanha",

  components: {
    BaseSelect,
    BaseDatePicker,
    BaseRangeDatePicker,
    TableFechamentoSemanais
  },

  mixins: [rules],

  data() {
    return {
      arrayFechamentos: [],
      checkEdited: false,
      date: new Date().toISOString().substr(0, 7),
      dates: [new Date().toISOString().substr(0, 10)],
      minDate: new Date().toISOString().substr(0, 7),
      maxnDate: new Date().toISOString().substr(0, 7),
      formCampanha: {
        nome_campanha: "",
        valor_custo: 0,
        perc_crescimento: 0,
        objetivo_campanha: "",
        id_tipo_fechamento: ""
      },
      optionsVuetifyMoney: {
        locale: "pt-BR",
        prefix: "R$",
        length: 11,
        precision: 2
      },
      optionsVuetifyPercent: {
        locale: "pt-BR",
        prefix: "%",
        length: 6,
        precision: 2
      },
      tipoFechamento: {
        id_tipo_dado: "",
        id_tipo_evento: "M",
        data_inicio: "",
        data_final: "",
        mes_ref: "",
        ano_ref: ""
      },
      tiposDados: {
        items: [],
        loading: false,
        selected: null
      },
      rules: {
        nomeLength: v =>
          (v && v.length <= 50) || "Campo deve ter no máximo 50 caracteres",
        objetivoLength: v =>
          (v && v.length <= 2000) || "Campo deve ter no máximo 2000 caracteres",
        maiorCinco: value =>
          parseFloat(value) >= 5 || "O valor deve ser igual ou maior que %5,00",
        maiorZero: value =>
          parseFloat(value) > 0 || "O valor deve maior que 0,00"
      }
    };
  },

  computed: {
    ...mapGetters({
      getUser: "getUser"
    })
  },

  methods: {
    ...mapActions({
      resetCampanhaState: "campanha/resetCampanhaState",
      actionCampanha: "campanha/actionCampanha"
    }),

    checkFormEdited() {
      this.checkEdited = true;
    },

    verificaDataMesFechado(idTipoDado) {
      if (this.getUser.nivel != "1" && this.getUser.nivel != "2") {
        if (new Date().getDate() > 7) {
          this.tipoFechamento.idTipoDado = "";
          const textoMes = this.tiposDados[idTipoDado];
          this.$notify({
            text: `Data limite de inclusão para o ${textoMes} ultrapassado. Prazo máximo até o 5ª dia útil`,
            type: "error"
          });
        }
        this.date = new Date().toISOString().substr(0, 7);
        this.minDate = this.date;
        this.maxnDate = this.date;
      }
    },

    formatDate(date, tipoDado) {
      const tipoDadosFormatos = {
        X: () => this.formatRangeDate(date),
        R: () => this.formatDateMesFechado(date),
        M: () => this.formatDateMesRef(date),
        C: () => this.formatDateSemanal(date)
      };

      tipoDadosFormatos[tipoDado]();
    },

    formatRangeDate(dates) {
      const arrDates = dates.map(date => parse(date, "yyyy-MM-dd", new Date()));

      arrDates.sort(compareAsc);
      this.tipoFechamento.data_inicio = format(
        arrDates[0],
        "yyyy-MM-dd HH:mm:ss"
      );
      this.tipoFechamento.data_final = format(
        arrDates[arrDates.length - 1],
        "yyyy-MM-dd HH:mm:ss"
      );

      const date = parse(
        this.tipoFechamento.data_final,
        "yyyy-MM-dd HH:mm:ss",
        new Date()
      );

      this.tipoFechamento.mes_ref = String(getMonth(date) + 1);
      this.tipoFechamento.ano_ref = String(getYear(date));
    },

    formatDateMesFechado(date) {
      this.setMonthAndYear(date);
      this.setDateInitialAndFinal(date);
    },

    formatDateMesRef(date) {
      const currentDate = parse(date, "yyyy-MM", new Date());
      this.tipoFechamento.data_inicio = format(
        setDate(subMonths(currentDate, 1), 26),
        "yyyy-MM-dd HH:mm:ss"
      );
      this.tipoFechamento.data_final = format(
        setDate(currentDate, 25),
        "yyyy-MM-dd HH:mm:ss"
      );

      this.setMonthAndYear(date);
    },

    async formatDateSemanal(date) {
      this.setMonthAndYear(date);
      this.setDateInitialAndFinal(date);
      await this.checkExistPeriodoFechamentoSemanal();
    },

    setMonthAndYear(date) {
      this.tipoFechamento.mes_ref = date.split("-")[1];
      this.tipoFechamento.ano_ref = date.split("-")[0];
    },

    setDateInitialAndFinal(date) {
      const currentDate = parse(date, "yyyy-MM", new Date());
      this.tipoFechamento.data_inicio = format(
        currentDate,
        "yyyy-MM-dd HH:mm:ss"
      );
      this.tipoFechamento.data_final = format(
        lastDayOfMonth(currentDate),
        "yyyy-MM-dd HH:mm:ss"
      );
    },

    async checkExistPeriodoFechamento() {
      const { data } = await comissao()
        .tipoFechamento()
        .show({
          ...this.tipoFechamento
        });
      return data;
    },

    async checkExistPeriodoFechamentoSemanal() {
      const { data } = await comissao()
        .tipoFechamento()
        .show({
          id_tipo_dado: this.tipoFechamento.id_tipo_dado,
          id_tipo_evento: this.tipoFechamento.id_tipo_evento,
          mes_ref: this.tipoFechamento.mes_ref,
          ano_ref: this.tipoFechamento.ano_ref
        });

      data.data.sort(
        (a, b) => new Date(a.data_inicio) - new Date(b.data_inicio)
      );

      this.arrayFechamentos = data.data.length > 0 ? data.data : [];

      return data;
    },

    async findIdTipoFechamento() {
      const checkPeriodoFechamento =
        this.tipoFechamento.idTipoDado == "C"
          ? await this.checkExistPeriodoFechamentoSemanal()
          : await this.checkExistPeriodoFechamento();
      if (checkPeriodoFechamento.total) {
        return checkPeriodoFechamento.data[0].id_tipo_fechamento;
      }

      if (this.tipoFechamento.idTipoDado !== "C") {
        const periodoFechamento = await this.sendPeriodoFechamento();
        return periodoFechamento.data.id_tipo_fechamento;
      }
    },

    async saveCampanha() {
      // verifica se houve aterção no formulario
      if (!this.checkEdited) return await this.nextStepEmpresas();

      //verifica se é edição
      if (this.$route.params.idCampanha) return await this.editiCampanha();

      //cadastra nova campanha
      return await this.postCampanha();
    },

    async postCampanha() {
      try {
        this.formCampanha.id_tipo_fechamento = await this.findIdTipoFechamento();

        const { data } = await campanhaService.postCampanha({
          ...this.formCampanha
        });

        const response = this.arrayFechamentos.map(item => {
          return campanhas()
            .campanhaTipoFechamentoSemanal()
            .store({
              id_campanha: data.id_campanha,
              id_tipo_fechamento: item.id_tipo_fechamento
            });
        });

        await Promise.all(response);

        this.$notify({
          text: "Campanha salva com sucesso!",
          type: "success"
        });

        await this.nextStepEmpresas(data.id_campanha);
      } catch (error) {
        this.$notify({
          text: "Erro ao criar a campanha",
          type: "error"
        });
      }
    },

    async editiCampanha() {
      try {
        this.formCampanha.id_tipo_fechamento = await this.findIdTipoFechamento();

        await campanhaService.putCampanha(this.$route.params.idCampanha, {
          ...this.formCampanha
        });

        this.$notify({
          text: "Campanha editada",
          type: "success"
        });
        this.nextStepEmpresas();
      } catch (error) {
        this.$notify({
          text: "Erro ao editar a campanha",
          type: "error"
        });
      }
    },

    async sendPeriodoFechamento() {
      try {
        const response = await comissao()
          .tipoFechamento()
          .store({
            ...this.tipoFechamento
          });
        return response;
      } catch (error) {
        return error;
      }
    },

    async fetchTiposDados() {
      try {
        this.tiposDados.loading = true;
        const { data } = await comissao()
          .tipoDados()
          .show({
            ativa_campanha: "S"
          });
        this.tiposDados.items = data.data;
      } catch (error) {
        this.$notify({ type: "error", text: "Erro Carregar Tipos de Dados" });
      } finally {
        this.tiposDados.loading = false;
      }
    },

    nextStepEmpresas(idCampanha = false) {
      if (idCampanha) {
        return this.$router.push(`cadastro/${idCampanha}/empresas`);
      }
      return this.$router.push(this.$route.params.idCampanha + "/empresas");
    },

    disabledSemanal() {
      if (
        this.tipoFechamento.id_tipo_dado == "C" &&
        this.arrayFechamentos.length == 0
      )
        return true;
      return false;
    },

    async setCampanhaForEditing() {
      const { data } = await campanhaService.getById(
        this.$route.params.idCampanha
      );
      this.formCampanha = data;

      this.setTipoDadoForEditing(data.id_tipo_dado);
      this.setDateForEditing(data);
    },

    setTipoDadoForEditing(idTipoDado) {
      this.tiposDados.selected = this.tiposDados.items.find(
        tipoDado => tipoDado.id_tipo_dado == idTipoDado
      );
      this.tipoFechamento.id_tipo_dado = idTipoDado;
    },

    setDateForEditing(campanha) {
      if (campanha.id_tipo_dado == "X") {
        this.dates[0] = campanha.data_inicio.substr(0, 10);
        this.dates[1] = campanha.data_final.substr(0, 10);

        this.tipoFechamento.data_inicio = campanha.data_inicio;
        this.tipoFechamento.data_final = campanha.data_final;
      } else {
        this.formatDate(
          campanha.data_inicio.substr(0, 7),
          campanha.id_tipo_dado
        );
      }
    }
  },

  async mounted() {
    await this.fetchTiposDados();
    if (this.$route.params.idCampanha) {
      await this.setCampanhaForEditing();
    }
  },

  beforeDestroy() {
    this.resetCampanhaState();
  }
};
</script>
