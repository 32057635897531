import BaseServiceHttp from "./BaseServiceHttp";
class Sistema extends BaseServiceHttp {
  constructor(id) {
    const resource = "sistemas";
    let relationship = {
      colaborador: "colaboradores",
      colaboradorEmpresa: "colaboradores-empresas",
      indicadores: "indicadores",
      anos: "anos",
      meses: "meses",
      empresa: "empresas",
      funcoes: "funcoes",
      tipoBandeiras: "tipos-bandeiras",
      usuario: "usuarios",
      permissoes: "permissoes",
      modulos: "modulos",
      menus: "menus",
      papeis: "papeis",
      niveis: "niveis",
      papeisPermissoes: "papeis-permissoes",
      colaboradoresDepartamentos: "colaboradores-departamentos",
      tiposIndicadores: "tipos-indicadores",

      funcaoNivel: "funcao-nivel",
      empresasGrupo: "empresas-grupo",
      regioes: "regioes"
    };
    super(id, resource, relationship);
  }
}

export default id => new Sistema(id);
