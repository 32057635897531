<template>
  <v-dialog v-model="dialog" persistent max-width="30%">
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar class="toolbar-color">
          <span class="headline ml-2">
            {{
              labelBtn != "Salvar"
                ? "Editar Meta Pagamento"
                : "Cadastrar Meta Pagamento"
            }}
          </span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row align="center">
            <v-col lg="12" md="412" sm="12" cols="12">
              <BaseSelect
                single-line
                label="Tipo Meta"
                item-text="tipo_meta"
                item-value="id_tipo_meta"
                service="campanhaService.tipoMeta"
                v-model="formBonusTipoMeta.id_tipo_meta"
                :rules="[rules.required]"
                clearable
              >
              </BaseSelect>
            </v-col>

            <v-col lg="12" md="412" sm="12" cols="12">
              <BaseSelect
                single-line
                label="Segmento"
                item-text="descricao"
                item-value="id_band"
                service="sistemaService.tipoBandeiras"
                clearable
                v-model="formBonusTipoMeta.id_band"
                :rules="[rules.required]"
              >
              </BaseSelect>
            </v-col>

            <v-col lg="12" md="412" sm="12" cols="12">
              <v-text-field
                v-model="formBonusTipoMeta.valor_max"
                label="Bônus Máximo"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="labelBtn != 'Salvar' ? put() : send()"
          >{{ labelBtn }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/inputRules";
import BaseSelect from "@/components/shared/BaseSelectService";
import campanha from "@/services/http/campanhaService";
import sistemaService from "../../../services/http/sistemaService";

export default {
  name: "ModalbonusTipoMeta",
  mixins: [rules],
  components: {
    BaseSelect
  },

  props: {
    bonusTipoMeta: {
      type: Object
    },
    dialog: {
      type: Boolean,
      default: false
    },
    labelBtn: {
      type: String
    }
  },

  data() {
    return {
      id_bonus_tipo_meta: undefined,
      campanhaService: {},
      formBonusTipoMeta: {},
      sistemaService: sistemaService
    };
  },
  watch: {
    bonusTipoMeta(value) {
      this.id_bonus_tipo_meta = value.id_bonus_tipo_meta;
      this.formBonusTipoMeta = {
        id_band: value.id_band,
        id_tipo_meta: value.id_tipo_meta,
        valor_max: value.valor_max
      };
    }
  },

  methods: {
    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },
    async send() {
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .bonusTipoMeta()
          .store(this.formBonusTipoMeta, {
            notification: true,
            menssage: "Bonus tipo para o tipo de meta cadastrado com sucesso!!"
          });
      }
      this.close();
    },

    async put() {
      const params = this.formBonusTipoMeta;
      const validateStatus = this.$refs.form.validate();
      if (validateStatus) {
        await campanha()
          .bonusTipoMeta(this.id_bonus_tipo_meta)
          .update(
            {
              id_band: params.id_band,
              id_tipo_meta: params.id_tipo_meta,
              valor_max: params.valor_max
            },
            {
              notification: true,
              message: "meta pagamento editada com sucesso!"
            }
          );
      }

      this.close();
    }
  }
};
</script>
