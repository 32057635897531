<template>
  <div>
    <Loader :active="loaderStatus" message="Gerando relatório..." />
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="items"
      :sort-desc="true"
      :loading="loading"
      :paginate="true"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Lista de Apurações</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <Filtro
            @selectedFilters="fetchCampanhaApuracao((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.nome_campanha`]="{ item }">
        <template v-if="Number(item.total_empresas) > 0">
          <v-badge
            v-if="Number(item.total_empresas) > 1"
            bordered
            :content="'+' + (Number(item.total_empresas) - 1)"
          >
            {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
          </v-badge>
          <template v-else>
            {{ item.nome_campanha | TitleCase }} - {{ item.apelido }}
          </template>
        </template>
        <template v-else>
          Não há empresas vinculadas
        </template>
      </template>

      <template v-slot:[`item.valor_custo`]="{ item }">
        {{ item.valor_custo | BrazilianCurrency }}
      </template>
      <template v-slot:[`item.aprovado_pgto`]="{ item }">
        <v-icon :color="aprovacaoPagamento(item.aprovado_pgto).color">{{
          aprovacaoPagamento(item.aprovado_pgto).icon
        }}</v-icon>
      </template>

      <template v-slot:[`item.perc_crescimento`]="{ item }">
        {{ item.perc_crescimento }}%
      </template>

      <template v-slot:[`item.data_inicio`]="{ item }">
        {{ item.data_inicio | BrazilianStandardDate }} -
        {{ item.data_final | BrazilianStandardDate }}
      </template>

      <template v-slot:[`item.data_aprovado`]="{ item }">
        {{ item.data_aprovado | BrazilianStandardDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <IconBottom
              :name="'view'"
              @click="to(item)"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span class="tooltip-small">Visualizar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <IconBottom
              :name="'document'"
              @click="getResumoCampanha(item)"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span class="tooltip-small">Ver em relatório</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <IconBottom
              :name="'company'"
              @click="empresasParticipantes(item)"
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <span class="tooltip-small">Ver empresas participantes</span>
        </v-tooltip>

        <!-- <IconBottom :name="'view'" @click="to(item)" /> -->
        <!-- <IconBottom :name="'document'" @click="getResumoCampanha(item)" /> -->
        <!-- <IconBottom :name="'company'" @click="empresasParticipantes(item)" /> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              color="primary"
              @click="checkHistory(item)"
              dark
              small
            >
              <v-icon small>mdi-timeline-check-outline</v-icon>
            </v-btn>
          </template>
          <span class="tooltip-small">Linha do tempo</span>
        </v-tooltip>
      </template>
    </BaseTable>
    <ModalEmpresasCampanha
      :dialog="dialog"
      :empresas="listaEpresas"
      @close="dialog = false"
    />
    <ModalTimeLine
      :dialog="hasHistory"
      @close="hasHistory = false"
      :historico="historicoAprocavaoPagamento"
      :campanha="nomeCampanha"
    />
  </div>
</template>

<script>
import dateMixin from "@/mixins/dateMixin";
import currencyMixin from "@/mixins/currencyMixin";
import noDataMixin from "@/mixins/noDataMixin";
import { mapActions } from "vuex";
import campanha from "@/services/http/campanhaService";
import sistema from "@/services/http/sistemaService";
import BaseTable from "@/components/shared/NewBaseTable";
import ModalEmpresasCampanha from "@/components/campanha/ModalEmpresasCampanha";
import Filtro from "./Filtro";
import IconBottom from "@/components/shared/bottons/IconBottom";
import jasperService from "@/services/http/jasperService";
import Loader from "@/components/shared/LoaderCometa";
import ModalTimeLine from "./ModalTimeLine";

export default {
  // name: "SectionListCampanha",

  components: {
    BaseTable,
    Filtro,
    ModalEmpresasCampanha,
    IconBottom,
    Loader,
    ModalTimeLine
  },

  mixins: [dateMixin, currencyMixin, noDataMixin],

  data() {
    return {
      loaderStatus: false,
      loading: false,
      items: [],
      listaEpresas: [],
      dialog: false,
      hasHistory: false,
      filters: {},
      empresa: "empresa",
      nomeCampanha: "",
      historicoAprocavaoPagamento: [],
      pagamento: {
        S: {
          icon: "mdi-check",
          color: "success"
        },
        N: {
          icon: "mdi-close",
          color: "error"
        },
        I: {
          icon: "mdi-dots-horizontal",
          color: "gray"
        }
      }
    };
  },

  computed: {
    headers() {
      let header = [
        { text: "ID", value: "id_campanha" },
        {
          text: "Pagamento",
          value: "aprovado_pgto",
          align: "center"
        },
        {
          text: "Campanha",
          align: "start",
          value: "nome_campanha"
        },
        { text: "Custo Estimado", value: "valor_custo", align: "right" },
        { text: "% Cresc.", value: "perc_crescimento", align: "right" },

        { text: "Período", value: "data_inicio" },
        { text: "Data Aprovação", value: "data_aprovado" },
        { text: "Aprovado Por", value: "nome_usuario" }
      ];

      if (this.canAccess([87])) {
        header.push({
          text: "Ações",
          value: "actions",
          sortable: false,
          align: "center"
        });
      }

      return header;
    }
  },

  methods: {
    ...mapActions({
      actionApuracaoCampanha: "campanha/actionApuracaoCampanha",
      fetchView: "sistema/fetchView"
    }),

    calculaDiaUtil() {
      const data = new Date();
      if (data.getDate() == 1) {
        return data.getMonth();
      }
      return data.getMonth() + 1;
    },

    to(item) {
      this.actionApuracaoCampanha(item);
      this.$router.push({ path: `apuracao/${item.id_campanha}` });
      this.fetchView(`${item.nome_campanha}
        - ${this.format(item.data_inicio)} a
        ${this.format(item.data_final)}`);
    },

    async fetchCampanhaApuracao(filter) {
      this.loading = true;
      let { data } = await campanha()
        .apuracao()
        .show({
          id_status: 1,
          per_page: -1,
          status_processamento: "S",
          ...filter
        });
      this.loading = false;
      this.items = data.data;
    },

    async empresasParticipantes(item) {
      try {
        const idCampanha = item.id_campanha;
        const empresasCampanhas = await campanha()
          .empresa()
          .show({
            id_campanha: idCampanha
          });
        const empresas = empresasCampanhas.data.data
          .map(item => item.id_empresa)
          .toString();

        const {
          data: { data }
        } = await sistema()
          .empresa()
          .show({ id_empresa: empresas });

        this.listaEpresas = data;
        this.dialog = true;
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Não foi possivel buscar as empresas"
        });
      }
    },

    aprovacaoPagamento(item) {
      return this.pagamento[item];
    },

    async getResumoCampanha(item) {
      this.loaderStatus = true;
      const filters = {
        in_id_campanha: item.id_campanha
      };

      let { data } = await jasperService()
        .campanha()
        .resumoCampanha()
        .show(filters);
      this.generatePdf(data);
      this.loaderStatus = false;
    },

    generatePdf(data) {
      var blob = new Blob([data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },

    checkHistory(item) {
      this.nomeCampanha = item.nome_campanha;
      this.fetchAprovacaoPagamento(item.id_campanha);
      this.hasHistory = this.historicoAprocavaoPagamento == true ? true : false;
    },

    async fetchAprovacaoPagamento(idCampanha) {
      let { data } = await campanha(idCampanha)
        .aprovacaoPagamentoLog()
        .show();
      this.historicoAprocavaoPagamento = data;
      this.hasHistory = true;
    }
  }
};
</script>
