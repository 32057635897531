var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.onEdite)?[_c('p',{staticClass:"subtitle-1 mb-0"},[_vm._v(" Para cada público alvo cadastre seus parâmetros de meta ")]),_c('p',{staticClass:"caption"},[_vm._v(" Os parâmetros serão exbidos com base no público alvo escolhido anteriormente ")])]:_vm._e(),_c('v-divider'),_c('TableFuncoesPublicoAlvo',{staticClass:"mb-5",attrs:{"onEdite":_vm.onEdite,"funcoes":_vm.getFuncoesPublicoAlvoSelecionado},on:{"onCadastrarMeta":function($event){return _vm.addItem($event)}}}),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1 mb-3",attrs:{"headers":_vm.headers,"items":_vm.getMetasCadastradas,"no-data-text":"Nenhuma meta cadastrada","sort-by":"cod_funcao","loading":_vm.tableMetasCadastradasloading,"loading-text":"Loading..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_vm._v(" Metas Cadastradas ")])]},proxy:true},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.funcao))+" ")]}},{key:"item.indicador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_indicador)+" - "+_vm._s(item.indicador)+" ")]}},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('BaseSegmento',{attrs:{"segmento":item.id_band}})]}},{key:"item.perc_cresc",fn:function(ref){
var item = ref.item;
return [(item.perc_cresc)?_c('span',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-finance")]),_vm._v(" "+_vm._s(item.perc_cresc)+"% ")],1):_c('span',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}},{key:"item.valor_bonus",fn:function(ref){
var item = ref.item;
return [(item.valor_bonus)?_c('span',[_vm._v(" "+_vm._s(_vm.valorBonus(item))+" ")]):_c('span',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}},{key:"item.valor_meta",fn:function(ref){
var item = ref.item;
return [(item.valor_meta)?_c('span',[_vm._v(" "+_vm._s(_vm.valorMeta(item))+" ")]):_c('span',[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1)]}},{key:"item.atrela_gmr",fn:function(ref){
var item = ref.item;
return [(item.atrela_gmr == 'S')?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):(item.atrela_gmr == 'N')?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.pec_gmr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pec_gmr)+"% ")]}},{key:"item.pec_gmr_dobro",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pec_gmr_dobro)+"% ")]}},{key:"item.maior_menor",fn:function(ref){
var item = ref.item;
return [(item.maior_menor === '>')?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"green"}},[_vm._v("mdi-arrow-up ")]),_vm._v(" Maior ")],1):_vm._e(),(item.maior_menor === '<')?_c('span',[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"blue"}},[_vm._v("mdi-arrow-down ")]),_vm._v(" Menor ")],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","icon":"","color":"primary","dark":""},on:{"click":function($event){return _vm.fetchColaboradores(item)}}},[_c('v-icon',[_vm._v("mdi-account-search")])],1),(_vm.onEdite)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","icon":"","color":"orange","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.onEdite)?_c('v-btn',{staticClass:"ml-1",attrs:{"small":"","icon":"","color":"error","dark":""},on:{"click":function($event){return _vm.removeItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)]}}],null,true)})],1),(_vm.redenrizarMetasProgressivas())?_c('tabela-meta-progressiva',{attrs:{"onEdite":_vm.onEdite}}):_vm._e(),_c('v-card',[(_vm.redenrizarMetasEquipes())?_c('TableMetaEquipes',{attrs:{"onEdite":_vm.onEdite}}):_vm._e()],1),_c('v-card',[(_vm.redenrizarMetaSemanal())?_c('TableMetaSemanal',{attrs:{"items":_vm.getMetasCadastradas}}):_vm._e()],1),_c('v-card',[(_vm.redenrizarMetasModelos())?_c('TabelaMetaModelo'):_vm._e()],1),_c('v-card',[(_vm.renderizarMetasChassi())?_c('TabelaMetaChassi'):_vm._e()],1),_c('ModalCadastroIndicadores',{ref:"modalCadastroIndicadoresRef",attrs:{"funcao":_vm.cadastroIndicador.funcao},on:{"campanhaFuncaoCadastrada":function($event){return _vm.campanhaFuncaoCadastrada()},"close":function($event){return _vm.closeModalCasdastroIndicador()}}}),_c('ModalColaboradoresPublicoAlvo',{attrs:{"items":_vm.colaboradoresEmpresa.data,"show":_vm.colaboradoresEmpresa.show},on:{"close":function($event){_vm.colaboradoresEmpresa.data = []}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }