var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Loader',{attrs:{"active":_vm.loaderStatus,"message":"Gerando relatório..."}}),_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-desc":true,"loading":_vm.loading,"paginate":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Apurações")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('Filtro',{on:{"selectedFilters":function($event){_vm.fetchCampanhaApuracao((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.nome_campanha",fn:function(ref){
var item = ref.item;
return [(Number(item.total_empresas) > 0)?[(Number(item.total_empresas) > 1)?_c('v-badge',{attrs:{"bordered":"","content":'+' + (Number(item.total_empresas) - 1)}},[_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" - "+_vm._s(item.apelido)+" ")]):[_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" - "+_vm._s(item.apelido)+" ")]]:[_vm._v(" Não há empresas vinculadas ")]]}},{key:"item.valor_custo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_custo))+" ")]}},{key:"item.aprovado_pgto",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.aprovacaoPagamento(item.aprovado_pgto).color}},[_vm._v(_vm._s(_vm.aprovacaoPagamento(item.aprovado_pgto).icon))])]}},{key:"item.perc_crescimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.perc_crescimento)+"% ")]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" - "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.data_aprovado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_aprovado))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('IconBottom',_vm._g(_vm._b({attrs:{"name":'view'},on:{"click":function($event){return _vm.to(item)}}},'IconBottom',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Visualizar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('IconBottom',_vm._g(_vm._b({attrs:{"name":'document'},on:{"click":function($event){return _vm.getResumoCampanha(item)}}},'IconBottom',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Ver em relatório")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('IconBottom',_vm._g(_vm._b({attrs:{"name":'company'},on:{"click":function($event){return _vm.empresasParticipantes(item)}}},'IconBottom',attrs,false),on))]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Ver empresas participantes")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","dark":"","small":""},on:{"click":function($event){return _vm.checkHistory(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-timeline-check-outline")])],1)]}}],null,true)},[_c('span',{staticClass:"tooltip-small"},[_vm._v("Linha do tempo")])])]}}],null,true)}),_c('ModalEmpresasCampanha',{attrs:{"dialog":_vm.dialog,"empresas":_vm.listaEpresas},on:{"close":function($event){_vm.dialog = false}}}),_c('ModalTimeLine',{attrs:{"dialog":_vm.hasHistory,"historico":_vm.historicoAprocavaoPagamento,"campanha":_vm.nomeCampanha},on:{"close":function($event){_vm.hasHistory = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }