var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.campanhas.data,"sort-by":"data_cad","sort-desc":true,"loading":_vm.campanhas.loading,"paginate":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Lista de Campanha")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('FilterCampaign',{on:{"selectedFilters":function($event){_vm.fetchCampanhas((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.nome_campanha",fn:function(ref){
var item = ref.item;
return [(Number(item.total_empresas) > 0)?[(Number(item.total_empresas) > 1)?_c('v-badge',{attrs:{"bordered":"","content":'+' + (Number(item.total_empresas) - 1)}},[_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" - "+_vm._s(item.apelido)+" ")]):[_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.nome_campanha))+" - "+_vm._s(item.apelido)+" ")]]:[_vm._v(" Não há empresas vinculadas ")]]}},{key:"item.valor_custo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_custo))+" ")]}},{key:"item.perc_crescimento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.perc_crescimento)+"% ")]}},{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_inicio))+" - "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_final))+" ")]}},{key:"item.data_cad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianStandardDate")(item.data_cad))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.status == 'S')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):(item.status == 'N')?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_c('v-icon',_vm._g(_vm._b({attrs:{"color":"gray"}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.statusCadastro[item.status] || "Sem informações"))])])]}},{key:"item.id_status_camp",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.status[item.id_status_camp].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.status[item.id_status_camp].text)+" ")])]}},_vm._l((_vm.$scopedSlots),function(_,scopedSlotName){return {key:scopedSlotName,fn:function(slotData){return [_vm._t(scopedSlotName,null,null,slotData)]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }