export default [
  {
    path: "parametros",
    component: () => import("@/views/parametros/Index"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "tipo-fechamento",
        name: "cadastroPeriodoFechamento",
        component: () => import("@/views/parametros/PeriodoFechamento"),
        meta: { requiresAuth: true }
      },
      {
        path: "consulta-dados",
        name: "consulta-dados",
        component: () => import("@/views/parametros/TipoDados"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-indicadores",
        name: "consultaIndicadores",
        component: () =>
          import("@/views/parametros/indicadores/ConsultaIndicadores"),
        meta: { requiresAuth: true }
      },
      {
        path: "/consulta-tipo-indicador",
        name: "consultaTipoIndicador",
        component: () => import("@/views/parametros/ConsultaTipoIndicador"),
        meta: { requiresAuth: true }
      },
      {
        path: "parametro-datas",
        name: "parametro-datas",
        component: () => import("@/views/parametros/ParametroData"),
        meta: { requiresAuth: true }
      },
      {
        path: "valor-fixo",
        name: "valor-fixo",
        component: () => import("@/views/parametros/ValorFixo"),
        meta: { requiresAuth: true }
      },
      {
        path: "produto-modelo",
        name: "produto-modelo",
        component: () => import("@/views/parametros/ProdutoModelo"),
        meta: { requiresAuth: true }
      },
      {
        path: "tipo-pagamento",
        name: "tipo-pagamento",
        component: () => import("@/views/parametros/TipoPagamento"),
        meta: { requiresAuth: true }
      },
      {
        path: "tipo-meta",
        name: "tipo-meta",
        component: () => import("@/views/parametros/TipoMeta"),
        meta: { requiresAuth: true }
      },
      {
        path: "tipo-meta-pagamento",
        name: "tipo-meta-pagamento",
        component: () => import("@/views/parametros/TipoMetaPagamento"),
        meta: { requiresAuth: true }
      },
      {
        path: "bonus-tipo-meta",
        name: "bonus-tipo-meta",
        component: () => import("@/views/parametros/BonusTipoMeta"),
        meta: { requiresAuth: true }
      },
      {
        path: "meta-funcao",
        name: "meta-funcao",
        component: () => import("@/views/parametros/MetaFuncao"),
        meta: { requiresAuth: true }
      }
    ]
  }
];
