<template>
  <!-- <v-navigation-drawer
    elevation-1
    class="container-drawer shadow"
    v-model="drawerData"
    mini-variant-width="70"
    app
    expand-on-hover
    color="#0f2a49"
    dark
    @input="updateDrawer"
    @transitionend="titleHidden = !titleHidden"
  > -->
  <v-navigation-drawer
    elevation-1
    class="container-drawer shadow"
    v-model="drawerData"
    mini-variant-width="70"
    app
    color="#0f2a49"
    dark
    @input="updateDrawer"
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          <!-- <v-img
            :class="titleHidden != true ? 'image-small' : 'image-large'"
            :src="require('@/assets/logo-grupo-cometa-negativo.svg')"
          /> -->
          <v-img
            :class="(titleHidden = true)"
            width="150"
            style="margin: 0 auto;"
            :src="require('@/assets/logo-grupo-cometa-negativo.svg')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <TheMenuSection
      v-for="section in sections"
      :key="section.title"
      :section="section"
      :titleHidden="titleHidden"
    />
  </v-navigation-drawer>
</template>

<script>
import TheMenuSection from "@/components/shared/TheMenuSection";

export default {
  name: "TheSideBar",

  props: {
    drawer: {
      type: Boolean,
      default: true
    }
  },

  components: {
    TheMenuSection
  },

  data() {
    return {
      titleHidden: false,
      sections: [
        {
          title: "EMPRESA",
          permissions: [
            1508,
            1509,
            1510,
            1511,
            1512,
            1466,
            1467,
            1469,
            1513,
            1441,
            1469
          ],

          items: [
            {
              title: "Empresas",
              icon: "mdi-domain",
              route: "/empresas",
              levels: 1,
              permissions: [1443, 1442, 1508, 1509, 1510, 1511, 1512],
              children: [
                {
                  title: "Consulta Empresas",
                  icon: "mdi-magnify",
                  permissions: 1443,
                  route: "/consulta-empresas",
                  levels: 0
                },
                {
                  title: "Bandeira",
                  icon: "mdi-flag-outline",
                  permissions: 1442,
                  route: "/consulta-bandeira",
                  levels: 0
                },
                {
                  title: "Departamento",
                  icon: "mdi-account-tie-outline",
                  permissions: 1509,
                  route: "/consulta-departamento",
                  levels: 0
                },
                {
                  title: "Equipes",
                  icon: "mdi-account-multiple-outline",
                  permissions: 1508,
                  route: "/consulta-equipe",
                  levels: 0
                },
                {
                  title: "Região",
                  icon: "mdi-map-outline",
                  permissions: 1510,
                  route: "/consulta-regiao",
                  levels: 0
                },
                {
                  title: "Grupo Comissão",
                  icon: "mdi-currency-usd",
                  permissions: 1511,
                  route: "/consulta-grupo-comissao",
                  levels: 0
                },
                {
                  title: "Grupo Empresas",
                  icon: "mdi-domain",
                  permissions: 1512,
                  route: "/consulta-grupo-empresas",
                  levels: 0
                }
              ]
            },
            {
              title: "Colaboradores",
              icon: "mdi-account-multiple-outline",
              route: "/colaboradores",
              permissions: [1466, 1467, 1469, 1513, 1441],
              levels: 1,
              children: [
                {
                  title: "Consulta Pessoa",
                  icon: "mdi-account-search-outline",
                  permissions: 1466,
                  route: "/consulta-pessoa"
                },
                {
                  title: "Colaborador Empresas",
                  icon: "mdi-account-search-outline",
                  permissions: 1467,
                  route: "/consulta-colaboradores-empresa"
                },
                {
                  title: "Colaborador Equipe",
                  icon: "mdi-account-outline",
                  permissions: 1513,
                  route: "/consulta-colaborador-equipe"
                },
                {
                  title: "Funções",
                  icon: "mdi-account-group-outline",
                  permissions: 1441,
                  route: "/consulta-funcoes"
                },
                {
                  title: "Função Nível",
                  icon: "mdi-badge-account-outline",
                  permissions: 1469,

                  route: "/consulta-funcao-nivel"
                }
              ]
            },
            {
              title: "Parâmentos Diversos",
              icon: "mdi-tune-variant",
              permissions: [
                661,
                1641,
                1742,
                1744,
                1743,
                1745,
                1746,
                1747,
                1748,
                1741,
                1749
              ],
              levels: 1,
              children: [
                {
                  title: "Indicadores",
                  icon: "mdi-finance",
                  route: "/consulta-indicadores",
                  permissions: 1741
                },
                {
                  title: "Tipo Indicador",
                  icon: "mdi-finance",
                  route: "/consulta-tipo-indicador",
                  permissions: 1641
                },
                {
                  title: "Parâmetro por Datas",
                  icon: "mdi-calendar-outline",
                  route: "/parametros/parametro-datas",
                  permissions: 1742
                },
                {
                  title: "Tipo Dados",
                  icon: "mdi-shape-outline",
                  route: "/parametros/consulta-dados",
                  permissions: 1743
                },
                {
                  title: "Tipo Fechamento",
                  icon: "mdi-calendar-cursor",
                  permissions: 1744,
                  route: "/parametros/tipo-fechamento"
                },
                {
                  title: "Valor Fixo",
                  icon: "mdi-finance",
                  route: "/parametros/valor-fixo",
                  permissions: 1749
                },
                {
                  title: "Produto Modelo",
                  icon: "mdi-finance",
                  route: "/parametros/produto-modelo",
                  permissions: 1745
                },
                {
                  title: "Tipo Pagamento",
                  icon: "mdi-finance",
                  route: "/parametros/tipo-pagamento",
                  permissions: 1746
                },
                {
                  title: "Tipo Meta",
                  icon: "mdi-finance",
                  route: "/parametros/tipo-meta",
                  permissions: 1747
                },
                {
                  title: "Bonus Tipo Meta",
                  icon: "mdi-finance",
                  route: "/parametros/bonus-tipo-meta",
                  permissions: 1748
                },
                {
                  title: "Tipo Meta Pagamento",
                  icon: "mdi-finance",
                  route: "/parametros/tipo-meta-pagamento",
                  permissions: 1748
                },
                {
                  title: "Meta Função",
                  icon: "mdi-tune-variant",
                  route: "/parametros/meta-funcao",
                  permissions: 1520
                }
              ]
            }
          ]
        },
        {
          title: "CAMPANHA",
          permissions: [1461, 1470, 1503, 1471, 1481, 1482, 1504],

          items: [
            {
              title: "Dashboard",
              icon: "mdi-view-dashboard-outline",
              route: "/dashboard",
              permissions: 1461,
              levels: 0
            },
            {
              title: "Consulta",
              icon: "mdi-magnify",
              route: "/campanhas/consulta",
              permissions: 1470,
              levels: 0
            },
            {
              title: "Aprovação",
              icon: "mdi-check-decagram-outline",
              route: "/campanhas/aprovacao",
              permissions: 1503,
              levels: 0
            },
            {
              title: "Metas",
              icon: "mdi-finance",
              route: "/campanhas/lista-metas",
              permissions: 1471,
              levels: 0
            },
            {
              title: "Realizados",
              icon: "mdi-file-document-outline",
              route: "/campanhas/lista-realizados",
              permissions: 1481,
              levels: 0
            },
            {
              title: "Apuração",
              icon: "mdi-clipboard-list-outline",
              route: "/campanhas/apuracao",
              permissions: 1482,
              levels: 0
            },
            {
              title: "Processamento de Campanha",
              icon: "mdi-file-cog-outline",
              route: "/campanhas/processamento",
              permissions: 1504,
              levels: 0
            }
          ]
        },
        // {
        //   title: "COMISSÃO",
        //   permissions: [
        //     1462,
        //     1514,
        //     1505,
        //     1506,
        //     1515,
        //     1516,
        //     1517,
        //     1518,
        //     1519,
        //     1520,
        //     1521,
        //     1522,
        //     1523,
        //     1507,
        //     1524,
        //     1525,
        //     1526,
        //     1527,
        //     1528,
        //     1529,
        //     1530,
        //     1531,
        //     1532,
        //     1463
        //   ],

        //   items: [
        //     {
        //       title: "Dashboard",
        //       icon: "mdi-view-dashboard-outline",
        //       route: "/comissao/dashboard",
        //       permissions: 1462,
        //       levels: 0
        //     },
        //     {
        //       title: "Comissão",
        //       icon: "mdi-currency-usd",
        //       route: "/comissao/comissao",
        //       permissions: 1514,
        //       levels: 0
        //     },
        //     {
        //       title: "Descritivo",
        //       icon: "mdi-file",
        //       route: "/comissao/descritivo",
        //       permissions: 1514,
        //       levels: 0
        //     },
        //     {
        //       title: "Depto Comercial",
        //       icon: "mdi-store-outline",
        //       permissions: [1505],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Consultor de Vendas",
        //           icon: "mdi-account-tie-outline",
        //           route: "/comissao/departamento-comercial-consultor-vendas",
        //           permissions: 1505
        //         }
        //       ]
        //     },
        //     {
        //       title: "Depto Pós-Vendas",
        //       icon: "mdi-cart-outline",
        //       permissions: [1506],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Parâmetros Empresa OS",
        //           icon: "mdi-chart-areaspline",
        //           route: "/comissao/parametros-pos-vendas",
        //           permissions: 1506
        //         }
        //       ]
        //     },
        //     {
        //       title: "Depto Administrativo",
        //       icon: "mdi-account-tie-outline",
        //       permissions: [1515, 1516],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Lançamento Manual",
        //           icon: "mdi-file-table-outline",
        //           route: "/comissao/administrativo-lancamento-manual",
        //           permissions: 1515
        //         },
        //         {
        //           title: "Exportar/listar",
        //           icon: "mdi-file-export-outline",
        //           route: "/comissao/administrativo-exportar-listar",
        //           permissions: 1516
        //         }
        //       ]
        //     },
        //     {
        //       title: "Metas",
        //       icon: "mdi-finance",
        //       permissions: [1517, 1518, 1519, 1520],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Individual",
        //           icon: "mdi-account-outline",
        //           route: "/comissao/meta-individual",
        //           permissions: 1517
        //         },
        //         {
        //           title: "Equipe",
        //           icon: "mdi-account-multiple-outline",
        //           route: "/comissao/meta-equipe",
        //           permissions: 1518
        //         },
        //         {
        //           title: "Empresa",
        //           icon: "mdi-domain",
        //           route: "/comissao/meta-empresa",
        //           permissions: 1519
        //         },
        //         {
        //           title: "Função",
        //           icon: "mdi-tune-variant",
        //           route: "/comissao/meta-funcao",
        //           permissions: 1520
        //         }
        //       ]
        //     },
        //     {
        //       title: "Realizados",
        //       icon: "mdi-file-document-outline",
        //       permissions: [1521, 1522, 1523],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Individual",
        //           icon: "mdi-account-outline",
        //           route: "/comissao/realizados-individual",
        //           permissions: 1521
        //         },
        //         {
        //           title: "Equipes",
        //           icon: "mdi-account-multiple-outline",
        //           route: "/comissao/realizados-equipes",
        //           permissions: 1522
        //         },
        //         {
        //           title: "Empresas",
        //           icon: "mdi-domain",
        //           route: "/comissao/realizados-empresas",
        //           permissions: 1523
        //         }
        //       ]
        //     },
        //     {
        //       title: "Relatórios",
        //       icon: "mdi-file-outline",
        //       permissions: [1507, 1524, 1525, 1526, 1527, 1528, 1529],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Empresa",
        //           icon: "mdi-domain",
        //           route: "/comissao/relatorios-empresa",
        //           permissions: 1507
        //         },
        //         {
        //           title: "Depto Comercial",
        //           icon: "mdi-store-outline",
        //           route: "/comissao/relatorios-depto-comercial",
        //           permissions: 1524
        //         },
        //         {
        //           title: "Depto Administrativo",
        //           icon: "mdi-account-tie-outline",
        //           route: "/comissao/relatorios-depto-administrativo",
        //           permissions: 1525
        //         },
        //         {
        //           title: "Colaborador",
        //           icon: "mdi-account-outline",
        //           route: "/comissao/relatorios-colaborador",
        //           permissions: 1526
        //         },
        //         {
        //           title: "Supervisor/Equipe",
        //           icon: "mdi-account-multiple-outline",
        //           route: "/comissao/relatorios-supervisor-equipe",
        //           permissions: 1527
        //         },
        //         {
        //           title: "Comissão",
        //           icon: "mdi-currency-usd",
        //           route: "/comissao/relatorios-comissao",
        //           permissions: 1528
        //         },
        //         {
        //           title: "Realizados",
        //           icon: "mdi-file-document-outline",
        //           route: "/comissao/relatorios-realizados",
        //           permissions: 1529
        //         }
        //       ]
        //     },
        //     {
        //       title: "Funções Parâmetros",
        //       icon: "mdi-tune-variant",
        //       permissions: [1530, 1531, 1532],
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Departamento",
        //           icon: "mdi-store-outline",
        //           route: "/comissao/funcoes-parametros-departamento",
        //           permissions: 1530
        //         },
        //         {
        //           title: "Equipes",
        //           icon: "mdi-account-multiple-outline",
        //           route: "/comissao/funcoes-parametros-equipe",
        //           permissions: 1531
        //         },
        //         {
        //           title: "Empresas",
        //           icon: "mdi-account-multiple-outline",
        //           route: "/comissao/funcoes-parametros-empresa",
        //           permissions: 1532
        //         }
        //       ]
        //     },
        //     {
        //       title: "Processamento de Comissão",
        //       icon: "mdi-file-cog-outline",
        //       permissions: 1463,
        //       route: "/comissao/processamento",
        //       levels: 0
        //     }
        //   ]
        // },
        {
          title: "SISTEMA",
          permissions: [1464, 1661, 1666, 1465, 1501, 1533, 1534, 1535, 1536],
          items: [
            {
              title: "Usuários",
              icon: "mdi-account-multiple-outline",
              route: "/sistema/consulta-usuarios",
              permissions: 1464,
              levels: 0
            },
            {
              title: "Status Consórcio",
              icon: " mdi-hand-coin",
              route: "/sistema/consulta-status-consorcio",
              permissions: 1661,
              levels: 0
            },
            {
              title: "Status Campanha",
              icon: " mdi-handshake-outline ",
              route: "/sistema/consulta-status-campanha",
              permissions: 1666,
              levels: 0
            },

            {
              title: "Módulos/Menus",
              icon: "mdi-layers-outline",
              route: "/sistema/consulta-modulo-menu",
              permissions: 1465,
              levels: 0
            },
            {
              title: "Papéis/Níveis",
              icon: "mdi-layers-outline",
              route: "/sistema/consulta-papel-nivel",
              permissions: 1501,
              levels: 0
            },
            {
              title: "Permissões",
              icon: "mdi-lock-outline",
              route: "/sistema/consulta-permissoes",
              permissions: 1533,
              levels: 0
            },
            {
              title: "Processos",
              icon: "mdi-cog-sync-outline",
              route: "/sistema/processos",
              permissions: 1534,
              levels: 0
            },
            {
              title: "Empresa Processa",
              icon: "mdi-cog-sync-outline",
              route: "/sistema/empresa-processa",
              permissions: 1535,
              levels: 0
            },
            {
              title: "Processo Parâmetros",
              icon: "mdi-cog-sync-outline",
              route: "/sistema/parametros-processos",
              permissions: 1536,
              levels: 0
            }
          ]
        }
        // {
        //   title: "EXEMPLO",
        //   items: [
        //     {
        //       title: "Exemplo 1 nível",
        //       icon: "mdi-test-tube",
        //       levels: 1,
        //       children: [
        //         {
        //           title: "Exemplo 1",
        //           icon: "mdi-bullhorn",
        //           route: "/exemplo"
        //         },
        //         {
        //           title: "Exemplo 2",
        //           icon: "mdi-bullhorn",
        //           route: "/exemplo2"
        //         }
        //       ]
        //     },
        //     {
        //       title: "Exemplo 2 níveis",
        //       icon: "mdi-test-tube",
        //       levels: 2,
        //       children: [
        //         {
        //           title: "Exemplo",
        //           grandchildren: [
        //             {
        //               title: "Exemplo",
        //               icon: "mdi-test-tube",
        //               route: "/exemplo3"
        //             }
        //           ]
        //         }
        //       ]
        //     }
        //   ]
        // }
      ],
      drawerData: true
    };
  },

  methods: {
    updateDrawer(newValueDrawer) {
      this.drawerData = newValueDrawer;
      this.$emit("setDrawerResponsiveness", newValueDrawer);
    }
  },

  watch: {
    drawer(value) {
      this.drawerData = value;
    }
  }
};
</script>
<style scoped>
.shadow {
  box-shadow: 0 4px 8px 5px rgba(0, 0, 0, 0.2),
    0 6px 20px 5px rgba(0, 0, 0, 0.19);
}

.image-small {
  margin: 0 auto;
  max-width: 32px;
  transition: max-width 0.3s;
}

.image-large {
  margin: 0 auto;
  max-width: 150px;
  transition: max-width 0.3s;
}
</style>
