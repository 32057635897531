var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"900px","transition":"dialog-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"page":_vm.page,"no-results-text":"Dados não encontrados","footer-props":{
        'items-per-page-text': 'Colaborador por pagina'
      }},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('close')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1)]},proxy:true},{key:"footer.page-text",fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" de "+_vm._s(items.pageStop)+" Total "+_vm._s(items.itemsLength)+" ")]}},{key:"item.colaborador",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("TitleCase")(item.colaborador))+" ")]}},{key:"item.id_band",fn:function(ref){
      var item = ref.item;
return [_c('Segmento',{attrs:{"segmento":item.id_band}})]}}],null,true)},[_c('v-data-footer',{attrs:{"items-per-page-text":"toa"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }